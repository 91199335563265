// 共通js

import "../sass/common.scss"; // 共通css

import htmx from "htmx.org";

import { mq } from "../jsmodules/_mediaQuery";
// import { toggleClassByScrollFn } from "../jsmodules/toggleClassByScroll";
import { toggleClassByClickFn } from "../jsmodules/toggleClassByClick";
import { cloneDomFn } from "../jsmodules/_cloneDom";
import { IOFn } from "../jsmodules/_observer";
import { SmoothScroll } from "../jsmodules/smoothScroll";
// import { Accordion } from "../jsmodules/accordion";

// スムーズスクロールインスタンス
const ss = new SmoothScroll();
ss.watch();

// const acc = new Accordion();

// ヘッダーに関する処理
const initHeader = () => {
  // スクロールするとbodyにクラスがつく
  toggleClassByClickFn("#js-menu-trigger", "body", "opened-menu");
  // toggleClassByScrollFn("header", "body");
  const scrollAddClass = () => {
    const scroll = window.scrollY;
    const header = document.getElementById("js-scrollChange");
    if (scroll > 250) {
      header.classList.add("active");
    } else {
      header.classList.remove("active");
    }
  };
  document.addEventListener("scroll", scrollAddClass);
};

// 無限スライダーをアシストする: 要素を複製＆durationなどを渡す
const setLoopSlide = () => {
  const SELECTOR = ".js-loopslide";
  const target = document.querySelectorAll(SELECTOR);

  if (target.length === 0) return;

  // アニメーション間隔のデフォルト値
  const DUARATION_DEFAULT_VALUE = 20;

  target.forEach((_t) => {
    const content = _t.querySelector(".js-loopslide-content");

    const clone = cloneDomFn(content);

    let duration = null;

    const { dur } = _t.dataset;

    // データ属性durがある場合
    if (typeof dur === "string") {
      if (/^\d+$/.test(dur)) {
        // 数値のみの場合
        duration = Number(dur);
      } else if (dur.includes(",")) {
        // 「,」つき数値の場合
        const durArray = dur.split(",").map(Number);

        duration = mq("pc") ? durArray[0] : durArray[1];

        durArray.forEach((num) => {
          if (Number.isNaN(num)) duration = DUARATION_DEFAULT_VALUE;
        });
      } else {
        // それ以外の場合（data-dur=""を想定）
        duration = DUARATION_DEFAULT_VALUE;
      }
    }

    // データ属性durがない場合
    if (typeof dur === "undefined") {
      duration = DUARATION_DEFAULT_VALUE;
    }

    const delay = (duration / 2) * -1;

    content.style.animationDelay = `${delay}s`;

    [content, clone].forEach((_e) => {
      const element = _e;
      element.style.animationDuration = `${duration}s`;
    });
  });

  // 表示領域外ではアニメーション停止
  IOFn(SELECTOR, "0%", "is-running", false);
};

// SP表示ナビの「SUPPORT」をトップページでクリックしたときにメニューを閉じる処理
const navCloseClick = () => {
  const navLinks = document.querySelectorAll(".nav a");
  navLinks.forEach((navLink) => {
    navLink.addEventListener("click", () => {
      setTimeout(() => {
        document.querySelector("body").classList.remove("opened-menu");
      }, 500);
    });
  });
};
// ▼▼▼▼▼ DOMが安定した後に実行
const HTMX_ELM_COUNT = htmx.findAll(`[hx-trigger="load"]`).length;
let LOADED_COUNT = 0;
htmx.on("htmx:afterSettle", () => {
  LOADED_COUNT += 1;

  if (HTMX_ELM_COUNT === LOADED_COUNT) {
    // ここにDOMが安定した後に実行したい処理を記述する
    initHeader();
    ss.addClassNameAllowsJump();
    navCloseClick();
  }
});
// ▲▲▲▲▲

document.addEventListener("DOMContentLoaded", () => {
  setLoopSlide();
  IOFn();
  // acc.init();
});
