import { margObjectFn } from "./_margObject";
import { mq } from "./_mediaQuery";

/**
 * URLパラメータ(?dest=id名)からidの位置までページ内ジャンプするクラス。
 * ?gap=数値 でスクロール量を補正する。
 */
export class SmoothScroll {
  /**
	 * 引数に設定情報（object）を入れて挙動を更新することができる
	 * @param {object | null} _config - 設定オブジェクト: {
			headerValid: bool, // ヘッダーの高さを遷移量に含める
			headerSelector: string, // ヘッダーのセレクタ
			baseGap: number, // 遷移量の調整値。
			behavior: "smooth" | "instant" | "auto" // 振る舞い
		}
	 */
  constructor(_config = null) {
    // 設定情報の原型
    this.originalConfig = {
      headerValid: true, // ヘッダーの高さを遷移量に含める
      headerSelector: "header", // ヘッダーのセレクタ
      baseGap: 0, // 遷移量の調整値。
      behavior: "smooth", // "smooth" | "instant" | "auto"
    };

    // 使用する設定情報
    this.config = _config ? margObjectFn({}, this.originalConfig, _config) : this.originalConfig;

    this.body = document.body;

    // 許可クラス: jump()起動を許可するためのクラス
    this.classNameAllowsJump = "may-jump";
  }

  /**
   * 遷移の量を返す。
   * @param {string} ID - ターゲット要素のID名
   * @param {number} gap - 遷移量の補正値
   * @returns {number | null} ターゲット要素が無効であればnullを返す。
   */
  getPosition(ID = "", gap = 0) {
    const destination = document.getElementById(ID);
    if (!destination) return null;

    // ヘッダーの高さを制定
    let headerHight = 0;
    if (this.config.headerValid) {
      const header = document.querySelector(this.config.headerSelector);
      headerHight = header.offsetHeight;
    }

    // ポジションの各値ややscrollTo()に必要な情報を取得
    const { baseGap } = this.config;
    const rect = destination.getBoundingClientRect().top;
    const offset = window.scrollY;

    // ポジションを制定
    const position = rect + baseGap + offset - headerHight + gap;

    return position;
  }

  /**
   * 遷移する
   * @param {number | null} position - 遷移量
   * @returns void
   */
  jump(position = null) {
    if (typeof position !== "number") return;
    window.scrollTo({
      top: position,
      behavior: this.config.behavior,
    });
  }

  /**
   * bodyに許可クラスを付与する
   */
  addClassNameAllowsJump() {
    document.body.classList.add(this.classNameAllowsJump);
  }

  /**
   * bodyのクラスを監視し、高さが変わったら付与されるクラスがあればjump()を発火させる。
   */
  watch() {
    // URLパラメータを取得
    const url = new URL(window.location.href);
    const parms = url.searchParams;
    if (!parms.has("dest")) return;

    // 遷移先の要素を取得
    const targetID = parms.get("dest");

    // 遷移量の調整値
    let gap = 0;
    if (parms.has("gap")) {
      const gapValue = Number(parms.get("gap"));
      gap = Number.isNaN(gapValue) ? 0 : Number(gapValue);
    }

    const position = this.getPosition(targetID, gap);

    const watchInterval = setInterval(() => {
      const { body } = document;
      if (body && body.classList.contains(this.classNameAllowsJump)) {
        this.jump(position);
        clearInterval(watchInterval);
      }
    }, 100);
  }

  /**
   * href="#〇〇"の〇〇に対応したIDを持つ要素にページ内遷移する。
   * data-gap="pc時の数値,sp時の数値"で遷移の調整量調整する
   * @param {function} func - 遷移後に実行する関数
   * @param {string} triggerClass - トリガー要素のセレクタ
   */
  jumpInpage(func = null, triggerClass = "a.js-jump-inpage") {
    const triggers = document.querySelectorAll(triggerClass);

    const clickHandle = (targetID, gap) => {
      const position = this.getPosition(targetID, gap);

      if (typeof position !== "number") return;
      this.jump(position);
      if (typeof func === "function") {
        func.apply(this);
      }
    };

    triggers.forEach((trigger) => {
      const { hash } = trigger;
      const targetID = hash ? hash.replace("#", "") : null;

      let gap = 0;
      const dataGap = trigger.dataset.gap;
      if (dataGap || dataGap === "") {
        const gapArray = dataGap.split(",").map(Number);
        if (typeof gapArray[1] === "undefined") {
          const value = gapArray[0];
          gapArray[1] = value;
        }
        gap = mq("pc") ? gapArray[0] : gapArray[1];
      }

      trigger.addEventListener("click", (self) => {
        self.preventDefault();
        clickHandle(targetID, gap);
      });
    });
  }
}

export default SmoothScroll;
