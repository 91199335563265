/**
 * 任意の要素をクリックすると、任意の要素にクラスがついたり取れたりする関数
 * @param {string} _trigger クリックの対象となる要素のクラス名
 * @param {dom | string} _target クラスがトグルする要素のクラス名 デフォルトで_triggerと同値
 * @param {function | null | false} _function クリック時についで実行する関数
 * @param {string} _classname トグルするクラス名、デフォルトで’is-active’
 */
export const toggleClassByClickFn = (_trigger, _target = _trigger, _classname = "is-active", _function) => {
  const trigger = document.querySelectorAll(_trigger);
  if (trigger.length === 0) return;

  // eslint-disable-next-line no-console
  let exec = () => console.log("ターゲット要素は無効です");

  // ターゲットがdomであれば
  if (_target instanceof HTMLElement) exec = (t) => t.classList.toggle(_classname);

  // ターゲットがストリングであり、その要素があれば
  if (typeof _target === "string") {
    exec = (t) => {
      const TARGET = document.querySelectorAll(t);
      if (TARGET.length === 0) return;
      TARGET.forEach((_t) => _t.classList.toggle(_classname));
    };
  }

  // クリックイベント
  trigger.forEach((_trg) => {
    _trg.addEventListener("click", () => {
      exec(_target);
      if (_function) _function();
    });
  });
};

export default toggleClassByClickFn;
