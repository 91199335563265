/**
 * インターセクションオブザーバー
 * @param {String} _selector - 観測対象の要素のクラス名。 デフォルト値: ".js-target-io"
 * @param {String} _rootMargin - 観測の領域。 デフォルト値: "0% 0% -20% 0%"
 * @param {String} _classname - 観測領域に入ったときに、_selectorに追加されるクラス名。デフォルト値: "is-entering"
 * @param {Boolean} _cancelObservation - trueにすると一度しか観測しなくなる。デフォルト値: true
 * @returns
 */
export const IOFn = (_selector = ".js-target-io", _rootMargin = "0% 0% -20% 0%", _classname = "is-entering", _cancelObservation = true) => {
  const targets = document.querySelectorAll(_selector);
  if (targets.length === 0) return;

  const options = {
    root: null,
    rootMargin: _rootMargin,
    threshold: 0,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(_classname);
        if (_cancelObservation) observer.unobserve(entry.target);
      } else if (!_cancelObservation) {
        entry.target.classList.remove(_classname);
      }
    });
  }, options);

  // 初期の監視
  targets.forEach((target) => observer.observe(target));

  // ページ内リンク遷移後に再度トリガーするための処理
  const scrollEventHandler = () => {
    targets.forEach((target) => observer.observe(target));
  };

  // ページ内リンクでの遷移後にスクロールイベントを強制的に発火させる
  window.addEventListener("load", scrollEventHandler);
  window.addEventListener("hashchange", scrollEventHandler);
};

export default IOFn;
