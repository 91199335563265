/**
 * デバイス判定
 *
 * @param {String: 'pc' or 'sp'} device
 * @return {boolean}
 */

export const mq = (device) => (window.matchMedia("(min-width:768px)").matches ? device === "pc" : device === "sp");

export default mq;
