/**
 * 兄弟要素として複製する関数
 * @param {String | DOM} _target - クローンする要素のクラス名かDOM
 * @returns {Array | DOM} - クローンされたDOMの配列か単体のDOM
 */

export const cloneDomFn = (_target) => {
  if (typeof _target === "string") {
    const targets = document.querySelectorAll(_target);
    const cloneDomArray = [];
    for (let i = 0; i < targets.length; i += 1) {
      const parent = targets[i].parentNode;
      const clone = targets[i].cloneNode(true);
      parent.append(clone);
      cloneDomArray.push(clone);
    }
    return cloneDomArray;
  }
  if (typeof _target === "object") {
    const parent = _target.parentNode;
    const clone = _target.cloneNode(true);
    parent.append(clone);
    return clone;
  }
  return false;
};

export default cloneDomFn;
